import { moment } from './timeUtils'

export const formatNumber = (num) => {
  if (typeof num === 'number') {
    // Round the number to two decimal places
    num = Math.round(num * 100) / 100;

    // Convert the number to a string
    const numStr = num.toString();

    // Split the number string into integer and decimal parts
    const [integerPart, decimalPart] = numStr.split('.');

    // Add commas to the integer part
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // If there's a decimal part, format it and return the complete string
    if (decimalPart !== undefined) {
      return `${formattedInteger}.${decimalPart.padEnd(2, '0')}`;
    }

    // Otherwise, just return the formatted integer part
    return formattedInteger;
  }
  return num;
};

export const getRealPhoneNumber = (phoneNumberString) => {
  return ('' + phoneNumberString).replace(/\D/g, '');
};

export const formatPhoneNumber = (phoneNumberString) => {
  // Remove non-numeric characters
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  // Check if the number is a valid US phone number
  const match = cleaned.match(/^.?.?.?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null; // Return null if the number is not valid
};

export const formatPhoneNumberWithoutPrefix = (phoneNumberString) => {
  // Remove non-numeric characters
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');

  // Define match patterns for various phone number formats
  const formats = [
    { pattern: /^(\d{3})(\d{3})(\d{4})/, format: '($1) $2-$3' }, // (XXX) XXX-XXXX
    { pattern: /^(\d{3})(\d{3})(\d{1,4})/, format: '($1) $2-$3' }, // (XXX) XXX-XXX or (XXX) XXX-X
    { pattern: /^(\d{3})(\d{1,3})/, format: '($1) $2' }, // (XXX) XXX or (XXX) XX
    { pattern: /^(\d{1,3})/, format: '($1' }, // (XXX or (XX or (X
  ];

  // Iterate over formats and return the first match
  for (const { pattern, format } of formats) {
    const match = cleaned.match(pattern);
    if (match) {
      return format.replace(/\$(\d)/g, (_, idx) => match[idx]); // Replace placeholders with matched values
    }
  }

  return null; // Return null if the number is not valid
};

export const formatDate = (date, isForQuery = false) => {
  if (!(date instanceof Date)) return String(date);

  if (isForQuery) return moment(date).format('YYYY-MM-DD hh:mm:ss');
  return moment(date).format('MM/DD/YYYY hh:mm:ss');
};

export const commaStyle = (price) => {
  let tmp = '';
  let value = Number(price);
  if (value < 1000) return value;
  while (value) {
    let ext = value % 1000;
    value = Math.floor(value / 1000);
    if (value >= 1) {
      tmp = String(ext).padStart(3, '0') + tmp;
      tmp = ',' + tmp;
    } else tmp = String(ext) + tmp;
  }
  return tmp;
};

export const getFirstLetters = (name) => {
  name = name.toUpperCase();
  const splitName = name.split(' ');

  if (splitName.length > 1) {
    return `${splitName[0][0]}${splitName[1][0]}`;
  } else {
    return `${splitName[0][0]}${splitName[0][1]}`;
  }
};

export const stringToColor = (str) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};
